import { onMounted } from '@vue/composition-api';

export default function useDocs() {
  const scrollToElement = id => {
    const block = document.getElementById(id);
    const boundingClientRect = block.getBoundingClientRect();
    const offset = boundingClientRect.top + window.pageYOffset - 80;

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  };

  const setSummaryWidth = () => {
    const knowledgeBaseOverlay = document.querySelector('.knowledge-base-summary-overlay');
    const knowledgeBase = document.querySelector('.knowledge-base-summary');
    const computedStyle = getComputedStyle(knowledgeBaseOverlay);
    const overlayWidth = parseFloat(knowledgeBaseOverlay.clientWidth);
    const width = overlayWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight);

    knowledgeBase.style.width = `${width}px`;
  };

  onMounted(() => {
    setSummaryWidth();

    window.addEventListener('resize', setSummaryWidth);
  });

  return {
    scrollToElement,
  };
}
