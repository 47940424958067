<template>
  <section id="docs-payments-integration">
    <v-card-title>
      <h3 class="text-break">Payments Integration</h3>
    </v-card-title>

    <ObtainingApiKey />

    <CreatePaymentSession />

    <v-card-title id='docs-payments-integration-process-payment'>
      <h4 class="text-break">&#10123; Process Payment</h4>
    </v-card-title>

    <ProcessPaymentApiFlow />

    <ResultPageSetup />
  </section>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  CreatePaymentSession,
  ObtainingApiKey,
  ProcessPaymentApiFlow,
  ResultPageSetup,
} from './_partials/documentation-blocks';

export default {
  components: {
    CreatePaymentSession,
    ResultPageSetup,
    ObtainingApiKey,
    ProcessPaymentApiFlow
  },

  setup() {
    const integrationGuideTab = ref(null);

    return {
      integrationGuideTab,
    };
  }
};
</script>
