<template>
  <section id='docs-payments-integration-process-payment-api-flow'>
   <v-card-subtitle>
      This guide provides comprehensive documentation on integrating payments seamlessly into your
      platform using HTTP API requests.
      This allows you to interact with our platform programmatically. This method offers flexibility
      and customization, empowering you to create a seamless payment experience tailored to your
      platform's requirements.
    </v-card-subtitle>

    <v-card-text>
      After successfully creating the payment session, as detailed in the initial step, it is
      imperative to gather the customer's payment information securely. This includes sensitive data
      such as the card number, cardholder name, expiration date, and security code.
    </v-card-text>

    <v-card-text>
      To proceed with the payment, securely send this collected data using the <code>POST</code> method to the
      <code>/payment-events/process</code> endpoint on Merchanto platform. Do not forget to
      include the <code>Api-Key</code> header with your API key to the request. Please include
      <code>paymentSessionId</code> along with card data to the payload.
    </v-card-text>

    <v-card-title><span class='subtitle-1'>Code example:</span></v-card-title>
    <v-card-text>
      <v-tabs v-model='processPaymentCodeExampleTab' class='code-examples-tabs'>
        <v-tab>Node.js</v-tab>
        <v-tab>PHP</v-tab>
      </v-tabs>

      <v-tabs-items v-model='processPaymentCodeExampleTab'>
        <v-tab-item>
          <NodeProcessPaymentExample />
        </v-tab-item>
        <v-tab-item>
          <PhpProcessPaymentExample />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-text>
      Ensure the timing aligns with the 10-minute session lifetime. If processing attempts occur
      beyond this window, an error will be triggered, indicating session expiration. The session
      will
      automatically extend by 10 minutes with each unsuccessful attempt, offering flexibility for
      customers to correct errors and ensure a smooth payment experience.
    </v-card-text>

    <v-card-title>Response Details</v-card-title>
    <v-card-text>
      The response from the /payment-events/process endpoint will contain the following fields:
      <ul class='mb-2'>
        <li>
          <code>paymentId</code> - The identifier of the payment, generated by the Merchanto
          platform
        </li>
        <li>
          <code>orderId</code> - The original order ID provided by your system to the
          <code>/create</code> endpoint
        </li>
        <li>
          <code>redirectUrl</code> - The link provided to the <code>/create</code> endpoint for
          post-payment redirection
        </li>
        <li>
          <code>paymentStatus</code> - Possible Values: <code><strong>completed</strong></code>
          (successful payment), <code><strong>failed</strong></code> (payment failure),
          <code><strong>enrolled_3ds</strong></code> (3D secure verification required)
        </li>
        <li>
          <code>secureRedirect</code> - optional, appears only if paymentStatus is <code><strong>enrolled_3ds</strong></code>.
          This field contains the link where you should redirect the customer to complete the 3D
          secure verification.
          After the 3D secure verification process is completed, the customer will be automatically
          redirected to the <code>redirectUrl</code> provided to the <code>/create</code> endpoint.
        </li>
      </ul>
    </v-card-text>

    <v-card-text><strong>Note:</strong> For more detailed information about possible responses and
      further API reference, please refer to the dedicated API reference section of this
      documentation.
    </v-card-text>
  </section>
</template>

<script>
import {
  NodeProcessPaymentExample,
  PhpProcessPaymentExample,
} from '../code-examples';
import { ref } from '@vue/composition-api';

export default {
  components: { NodeProcessPaymentExample, PhpProcessPaymentExample },
  setup() {
    const processPaymentCodeExampleTab = ref(null);

    return {
      processPaymentCodeExampleTab,
    };
  },
};
</script>
