<template>
  <div id='knowledge-base'>
    <v-row>
      <v-col cols='12' md='3' lg='3' order='1' order-md='2' class='knowledge-base-summary-overlay'>
        <v-card class='knowledge-base-summary'>
          <div class='kb-questions py-1'>
            <div v-for='paragraph in paymentsIntegrationSummary' :key='paragraph.title'>
              <div class='kb-question d-flex align-start text-sm px-5 my-4 cursor-pointer'>
                <v-icon size='14' class='me-2 mt-1' color='secondary'>
                  {{ icons.mdiFileDocumentMultiple }}
                </v-icon>
                <span class='text--secondary' @click='scrollToElement(paragraph.link)'>{{ paragraph.title }}</span>
              </div>

              <ul v-if='paragraph.children'>
                <li v-for='subparagraph in paragraph.children' :key="subparagraph.title" class='kb-question d-flex align-start text-sm px-5 my-4 cursor-pointer'>
                  <v-icon size='14' class='me-2 mt-1' color='secondary'>
                    {{ icons.mdiTextBoxOutline }}
                  </v-icon>
                  <span class='text--secondary' @click='scrollToElement(subparagraph.link)'>{{ subparagraph.title }}</span>
                </li>
              </ul>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols='12' md='9' lg='9' order='1' order-md='2'>
        <v-card>
          <v-card-title>
            <h2 class='text-break'>Merchanto Payments</h2>
          </v-card-title>
          <v-card-title>
            <h5 class='text-break mt-2'>Integrate payments with the Merchanto Payments API.</h5>
          </v-card-title>

          <v-card-subtitle class='py-2'>
            <p class='text-break'>Integrating payments into your website is straightforward with Merchanto Payments. Currently, the only method available is through the modern API, which provides maximum flexibility and customization.</p>

            <p class='text-break'>To begin, you can integrate payments by creating a Checkout Session and collecting customer payment details directly through the API. This approach requires some coding but offers greater control and customization, ensuring that the payment process aligns perfectly with your site's design and user experience.</p>
          </v-card-subtitle>

          <PaymentsIntegration />

          <TestCards />

          <ApiReference />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiTextBoxOutline, mdiFileDocumentMultiple } from '@mdi/js';
import useDocs from '@/views/docs/useDocs';
import {
  ApiReference,
  PaymentsIntegration,
  TestCards,
} from '@/views/docs/payments/components';

export default {
  components: {
    ApiReference,
    PaymentsIntegration,
    TestCards,
  },
  setup() {
    const paymentsIntegrationSummary = [
      {
        title: 'Payments Integration', link: 'docs-payments-integration', children: [
          {
            title: '1. Create Payment Session',
            link: 'docs-payments-integration-create-payment-session',
          },
          { title: '2. Process Payment', link: 'docs-payments-integration-process-payment' },
          { title: '3. Setup Result Page', link: 'docs-payments-integration-setup-result-page' },
        ],
      },
      {
        title: 'Test Your Integration', link: 'docs-payments-integration-test-integration', children: [
          { title: '1. LPB', link: 'docs-payments-integration-test-integration-lpb' },
          { title: '2. Stripe', link: 'docs-payments-integration-test-integration-stripe' },
        ],
      },
      {
        title: 'API Reference', link: 'docs-payments-integration-api-reference', children: [
          {
            title: '1. API URL and Endpoints',
            link: 'docs-payments-integration-api-reference-url-endpoints',
          },
          {
            title: '2. Create Payment',
            link: 'docs-payments-integration-api-reference-create-payment-session',
          },
          {
            title: '3. Process Payment',
            link: 'docs-payments-integration-api-reference-process-payment',
          },
          {
            title: '4. Get Payment details',
            link: 'docs-payments-integration-api-reference-get-payment-details',
          },
        ],
      },
    ];

    const { scrollToElement } = useDocs();

    return {
      paymentsIntegrationSummary,
      icons: { mdiTextBoxOutline, mdiFileDocumentMultiple },
      scrollToElement,
    };
  },
};
</script>

<style lang='scss'>
@import '@core/preset/preset/pages/knowledge-base.scss';
</style>
