<template>
  <section id="docs-payments-integration-api-reference">
    <v-card-title>
      <h3 class="text-break">Complete API Reference</h3>
    </v-card-title>

    <ApiUrl />

    <ApiCreateEndpoint />

    <ApiProcessEndpoint />

    <ApiDetailsEndpoint />
  </section>
</template>

<script>
import {
  ApiCreateEndpoint,
  ApiDetailsEndpoint,
  ApiProcessEndpoint,
  ApiUrl,
} from './_partials/api-endpoints';

export default {
  components: {
    ApiCreateEndpoint,
    ApiDetailsEndpoint,
    ApiProcessEndpoint,
    ApiUrl,
  },
};
</script>
